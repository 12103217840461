import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class OAuthTestingService {
  hasValidAccessToken(): boolean {
    return false;
  }
}
