import {Component, Input, OnInit} from '@angular/core';
import {ApiModel} from '../../model/api.model';
import {CheckAllService} from '../../services/check-all/check-all.service';

@Component({
  selector: 'app-list-check-elements',
  templateUrl: './list-check-elements.component.html',
  styleUrls: ['./list-check-elements.component.scss']
})
export class ListCheckElementsComponent implements OnInit {
  @Input() servicesList: ApiModel[];
  // servicesList: ApiModel[];

  constructor(public checkAllService: CheckAllService) { }

  ngOnInit(): void {
    // this.checkAllService.getServices().then(resp => {
    //   let value = resp;
    //   value = value.map((api: ApiModel) => this.mapHealthRestApi(api));
    //   this.servicesList = value;
    //   console.log("serviceList: " + JSON.stringify(this.servicesList));
    // });
  }

  mapHealthRestApi(model: ApiModel): ApiModel {
    this.checkAllService.getRestApiHealth().subscribe(
      resp => {
        if (resp.status === 200) {
          model.up = true;
          model.message = resp.statusText;
        } else {
          model.message = resp.status + ' - ' + resp.statusText;
        }
      },
      error => {
        model.message = error.status + ' - ' + error.statusText;
      }
    )
    return model;
  }
}
