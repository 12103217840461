import {Pipe, PipeTransform} from '@angular/core';

/*
 * Format a Redevable identification number for display
 * For a BisNumber or National Number the format is: XXXXXX XXX XX
 * For a BCE Number the format is: XXXX XXX XXX
 *
 * Usage:
 *   value | redevableIdentification
*/
@Pipe({name: 'redevableIdentification'})
export class RedevableIdentificationPipe implements PipeTransform {
  readonly onlyNumbersRegex = /^\d+$/;

  transform(value: string): string {

    if (this.isValidBCENumber(value)) {
      return value.substr(0, 4) + '.' + value.substr(4, 3) + '.' + value.substr(7, 3);
    } else if (this.isValidBisNumber(value)) {
      return value.substr(0, 6) + ' ' + value.substr(6, 3) + ' ' + value.substr(9, 2);
    } else if (this.isValidNationalNumber(value)) {
      return value.substr(0, 6) + ' ' + value.substr(6, 3) + ' ' + value.substr(9, 2);
    } else {
      return value;
    }
  }

  /**
   * Checks if the specified value is a valid national number.
   * For it to be:
   *  - it must contains only numbers
   *  - it must contains exactly 11 numbers
   *  - the 8 first digits must be equal to the last two digits modulo 97
   *
   * @param value String to check
   */
  isValidNationalNumber(value: string): boolean {
    if (!value) {
      return false;
    }
    const matchRegEx = value.match(new RegExp(this.onlyNumbersRegex)).index > -1;
    return value.length === 11 && matchRegEx && (this.getModulo97(+value.substr(0, 9)) === +value.substr(9, 2)
      || this.getModulo97(+value.substr(0, 9) + 2000000000) === +value.substr(9, 2));
  }

  /**
   * Returns true if the nn is a bis number, else false. Bis number: - modulo
   * 97 - month is higher than or equal to 20
   * @param value String to check
   */
  isValidBisNumber(value: string): boolean {
    return this.isValidNationalNumber(value)
      && (+(value.substr(2, 2)) > 19);
  }

  isValidBCENumber(value: string): boolean {
    if (!value) {
      return false;
    }
    const matchRegEx = value.match(this.onlyNumbersRegex).index > -1;
    return value.length === 10 && matchRegEx
      && this.getModulo97(+value.padStart(10, '0').substr(0, 8)) === +value.padStart(10, '0').substr(8, 2);
  }


  /**
   * Returns the value of nb modulo 97.
   * @param nb Input number to get modulo 97
   */
  getModulo97(nb: number): number {
    return 97 - nb % 97;
  }
}
