export enum EnumTypeCommentaire {
    BLOQUE="BLOQUE",				// Constructeur bloqué
    DESACTIVE="DESACTIVE",			// Constructeur désactivé
    CONSTRUCTEUR="CONSTRUCTEUR",	// Commentaire général pour un constructeur
    SERVICETECHNIQUE="SERVTECH",	// Commentaire général pour un service technique
    DEMANDE="DEMANDE",				// Commentaire général pour une demande
    DESACTIVE_SITE_PRODUCTION="DESACTIVESITEPROD",		// Site de production désactivé
    REFUS_SIGNATURE="REFUSSIGN", // Refus lors de la signature
    PROJET_CERTIFICAT_A_VALIDER="PCV", // projet de certificat à valider par le constructeur iFAST
    DEMANDE_INFORMATION_COMPLEMENTAIRE="INFO" // demande d'information complémentaire (envoyé au constructeur)

}
