<div class="home-container container">
  <div class="cover-wrapper-2 row no-gutters">
    <div class="cover-wrapper col-md-6 col-xs-12" >
      <div class="cover" style="background-image: url({{homeDecorations[0].url}}); scale: 125%;">
      </div>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="cover-2 row flex-column no-gutters">
        <div class="info-title">
          <h1>{{'home.titre' | translate}}</h1>
        </div>
        <div class="info-details" style="padding-top: 30px;">
          <p>{{'home.description' | translate}}</p>
        </div>
        <div class="info-details" style="padding-top: 2px;">
          <p style="font-style: italic;">Application for processing vehicles approval requests</p>
        </div>
        <div class="sign-in">
          <button type="button" (click)="onConnect()">
            {{'common.me-connecter' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
