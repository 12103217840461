import {Component, OnInit, ViewChild} from '@angular/core';
import {TableReferenceService} from "../../../core/services/table-reference.service";
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {NewsService} from "../../../core/services/news.service";
import {News} from "../../../core/models/news.model";
import {Table} from "primeng/table";

@Component({
  selector: 'app-list-news-form',
  templateUrl: './list-news-form.component.html',
  styleUrls: ['./list-news-form.component.scss'],
  providers: [ConfirmationService, TranslateService]
})
export class ListNewsFormComponent implements OnInit {


  selectedNews: News;
  displayNewsDialog: boolean = false;
  news? : News[];
  newsRead?: News[];
  newsNotRead?: News[];


  constructor(private tableReferenceService: TableReferenceService, private newsService: NewsService
              ) { }
  ngOnInit(): void {


    this.newsService.getPublishedNews().subscribe((res: any) => {
      this.news = res.body;
      this.filterListReadUnRead();
    });



    // load in the cache the reference table commentaireStandard
    if (this.tableReferenceService.apiCache.get("commentaireStandard") == null) {
      this.tableReferenceService.getReferenceTable("commentaireStandard").subscribe(response => {
           this.tableReferenceService.apiCache.set("commentaireStandard", response.body);
      });
    }
  }

  onRowSelect(event: any){
    this.selectedNews = event.data;
  }

  filterListReadUnRead(){
    this.newsRead = this.news.filter(val => val.read == true);
    this.newsNotRead = this.news.filter(val => val.read == false);
  }

  onRowUnselect(event: any){
    this.selectedNews = null;
  }

  resetSelectedNews(){
    this.selectedNews = null;
  }

  readNews(){
    this.newsService.readNews(this.selectedNews.id).subscribe((res: any) => {
      this.news = res.body;
      this.filterListReadUnRead();
    });
    this.selectedNews = null;
  }

  unReadNews(){
    this.newsService.unReadNews(this.selectedNews.id).subscribe((res: any) => {
      this.news = res.body;
      this.filterListReadUnRead();
    });
    this.selectedNews = null;
  }


  getLabelForNumber(tablename: string, code: number): string {
    return this.tableReferenceService.apiCache.getLabelForNumber(tablename, code);
  }
  getLabelForString(tablename: string, code: string): string {
    return this.tableReferenceService.apiCache.getLabelForString(tablename, code);
  }
  getReferenceTable(tablename: string) {
    return this.tableReferenceService.apiCache.get(tablename);
  }

  clear(table: Table) {
    table.clear();
    table.reset();
    this.selectedNews = null;
  }


}
