export class UserBO {
  id?: string;
  fullName?: string;
  email?: string;
  langue?: string;
  roles?: string[];
  serviceTechnique?: boolean;
  constructeur?: boolean;
  mandataire?: boolean;
  agentDO215?: boolean;
  superUser?: boolean;
  agentConstructeur?: boolean;
  responsableAdministratif?: boolean;
  responsableTechnique?: boolean;
  administrateurSysteme?: boolean;
  responsableFinancier?: boolean;
  agentTechnique?: boolean;
  agentAdministratif?: boolean;
  auditeurInterne?: boolean;
  agentAutreRegion?: boolean;
  signataire?: boolean;
  constructeurId?: number;    // lien vers le constructeur pour lequel il est collaborateur (wtasConstructeurTiers)
  serviceTechniqueId?: number;    // lien vers le service technique pour lequel il est collaborateur (wtasServiceTechnique)
  mandataireId?: number;      // lien vers le mandataire pour lequel il est collaborateur (wtasMandataire)
  ifast?: boolean;


}
