import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CommonService} from '..';
import {OAuthService} from 'angular-oauth2-oidc';


@Injectable()
export class SecuredHttpInterceptor implements HttpInterceptor {

  constructor(private oAuthService: OAuthService) {

  }

  /**
   * Intercepts the http request and add the bearer token of the currently logged user.
   *
   * @param request http request
   * @param next http handler
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request || !request.url || (/^http/.test(request.url) && !(request.url.startsWith(CommonService.appConfig.backendUrl)))) {
      return next.handle(request);
    }
    if (this.oAuthService.hasValidAccessToken()) {
      const token = this.oAuthService.getAccessToken();
      request = request.clone({
        headers: request.headers.append('Authorization', `Bearer ${token}`),
      });
      return next.handle(request);
    }
    if (CommonService.appConfig.disableSecurity) {
      return next.handle(request);
    }
    return null;
  }
}
