/************* COMPONENTS ***********/

export * from './layout/template/template.component' ;
export * from './layout/footer/footer.component' ;
export * from './layout/header/header.component' ;
export * from './components/error/error.component' ;
export * from './components/page-ribbon/page-ribbon.component' ;

/************* DIRECTIVES ***********/

export * from './directive/has-permission' ;
export * from './directive/has-role' ;
export * from './directive/date-mask.directive' ;

/************* MODELS ***********/


/************* PIPES ***********/
export * from './pipe/communication.pipe' ;
export * from './pipe/redevable-Identification.pipe' ;
export * from './pipe/strip-html.pipe';
export * from './pipe/yes-or-no.pipe';
export * from './pipe/get-first-letter-vehcathomologation.pipe';
export * from './pipe/vehcathomologation-is-l4.pipe';

/************* TESTING ***********/

export * from './testing/common-testing.service' ;
export * from './testing/oauth-testing.service' ;
export * from './testing/translate-testing.module' ;
