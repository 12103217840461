export class AuthConfig {
  issuer: string;
  redirectUri: string;
  clientId: string;
  responseType: string;
  scope: string;
  silentRefreshRedirectUri: string;
  useSilentRefresh: boolean;
  showDebugInformation: boolean;
  sessionChecksEnabled: boolean;
  timeoutFactor: number;
  clearHashAfterLogin: boolean;
}
