import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OAuthService} from 'angular-oauth2-oidc';

@Component({
  selector: 'ui-session-expired',
  templateUrl: './session-expired-dialog.component.html',
  styleUrls: ['./session-expired-dialog.component.scss']
})

export class SessionExpiredDialogComponent implements OnInit {

  redirectUri: string;

  constructor(public dialogRef: MatDialogRef<SessionExpiredDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private oAuthService: OAuthService) {
    // Update view with given values
  }

  ngOnInit(): void {
  }

  onConfirm(): void {
    this.redirectUri = this.oAuthService.redirectUri;
    this.oAuthService.redirectUri = this.oAuthService.silentRefreshRedirectUri;
    this.oAuthService.initLoginFlowInPopup().then();
    setTimeout(() => {
      this.oAuthService.redirectUri = this.redirectUri;
      this.dialogRef.close(true);
    })
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
