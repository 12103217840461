/**
 * Used in routing to restrict access to a lazily loaded module
 */
export interface PermissionGuard {
  permissionScope?: Permission;
  only?: Array<string>;
  except?: Array<string>;
  redirectTo?: string | void;
}


export class Permission {
  resource: string;
  scope: string;


  constructor(resource: string, scope: string) {
    this.resource = resource;
    this.scope = scope;
  }
}
