import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SessionExpiredDialogComponent} from './ui-components';
import {CommonService, LoaderService} from './core';
import {OAuthService} from 'angular-oauth2-oidc';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'WTAS';

  constructor(public loaderService: LoaderService,
              private translate: TranslateService,
              private dialog: MatDialog,
              private oAuthService: OAuthService) {

    if (CommonService.userInfo && CommonService.userInfo.locale) {
      translate.use(CommonService.userInfo.locale);
    }
// this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('fr');
  }

  ngOnInit(): void {
    this.oAuthService.events.subscribe((event) => {
      if (event.type.toString() === 'silent_refresh_error') {
        setTimeout(() => {
          this.onSessionExpired();
        })
      }
    });

  }

  onSessionExpired(): void {
    this.getSessionExpiredDialog()
      .afterClosed().subscribe((resp: boolean) => {
      if (!resp) {
        this.oAuthService.logOut();
      }
    });
  }

  // TODO - remove SessionExpiredDialogComponent
  getSessionExpiredDialog(): MatDialogRef<SessionExpiredDialogComponent> {
    return this.dialog.open(SessionExpiredDialogComponent, {
      width: '500px',
      disableClose: true
    });
  }
}
