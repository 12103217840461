<div class="template">
  <p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>

  <app-header [authenticated]="authenticated" [hidden]="menubar.expanded"></app-header>
  <!-- TODO - remove Material from Menu -->
  <app-menu-bar [hidden]="!authenticated || !inApp" [expandable]="true" #menubar></app-menu-bar>
  <div class="content"
       [class.no-menubar]="!authenticated"
       [class.expanded-content]="menubar.expanded">
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer [hidden]="menubar.expanded"></app-footer>
</div>
