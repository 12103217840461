import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, of, Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {CommonService, Permission, ScopePermission, UrlFromProperties, UserInfo} from '..';


@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private scopePermission: ScopePermission[] = [];
  permissionUrl = 'permissions';


  constructor(private http: HttpClient) {
  }

  getScopePermissions(): ScopePermission[] {
    return this.scopePermission;
  }

  fetch(): Observable<HttpResponse<ScopePermission[]>> {
    return this.http.get<ScopePermission[]>(`${CommonService.appConfig.backendUrl}${this.permissionUrl}/get-permissions`,
      {observe: 'response'});
  }

  getActiveUserAccountsByRole(roleName: string): Observable<HttpResponse<UserInfo[]>> {
    return this.http.get<UserInfo[]>(`${CommonService.appConfig.backendUrl}${this.permissionUrl}/user/get-all-by-role/${roleName}`,
      {observe: 'response'});
  }

  getActiveUserAccountsByRoles(roleNames: string[]): Observable<HttpResponse<UserInfo[]>> {
    return this.http.get<UserInfo[]>(`${CommonService.appConfig.backendUrl}${this.permissionUrl}/user/get-all-by-roles/${roleNames}`,
      {observe: 'response'});
  }

  getActiveUserAccounts(): Observable<HttpResponse<UserInfo[]>> {
    return this.http.get<UserInfo[]>(`${CommonService.appConfig.backendUrl}${this.permissionUrl}/user/get-all`,
      {observe: 'response'});
  }

  getUserPermissions(force?: boolean): Observable<ScopePermission[]> {
    if (force) {
      this.scopePermission = [];
      // retrieve the  data from the server
      return this.fetch()
        .pipe(switchMap(response => {
          this.scopePermission = response.body;
          return of(this.scopePermission);
        }));
    } else {
      return of(this.scopePermission);
    }

  }


  /**
   * Checks if the logged user has the permissionScope specified
   *
   * @param resourceName keycloak resourceName
   * @param scopeName keycloak scopeName
   */
  hasScopePermission(resourceName: string, scopeName: string): Observable<boolean> {
    return of(this.scopePermission)
      .pipe(switchMap(permissionScope => {
          return of(permissionScope.filter(val => (val.resourceName === resourceName)
            && (val.scopes.indexOf(scopeName) !== -1)).length > 0);
        }
      ));
  }

  /**
   * Checks if the logged user has the permissionScope specified
   *
   * @param permission Keyclaok permission
   */
  hasPermission(permission: Permission): boolean {
    return this.scopePermission.filter(val => (val.resourceName === permission.resource)
      && (val.scopes.indexOf(permission.scope) !== -1)).length > 0;
  }


  fetchProperties(): Subscription {
    return this.http.get<UrlFromProperties>(`${CommonService.appConfig.backendUrl}${this.permissionUrl}/get-url-properties`,
      {observe: 'response'})
      .subscribe(resp => CommonService.urlProperties = resp.body)
      ;
  }

}
