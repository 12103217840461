import { Injectable } from '@angular/core';
import {AbstractBusinessService} from "./abstract-business.service";
import {CommonService} from "./common.service";
import {HttpClient, HttpResponse, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";

import {TableReferenceBO} from "../models/table_reference.model";
import {TableReference} from "../../modules/administration/model/table-reference.model";

@Injectable({
  providedIn: 'root'
})
export class TableReferenceService extends AbstractBusinessService<TableReferenceBO> {

  entityIdParamName: string;

  resourceUrl = CommonService.appConfig.backendUrl + 'tablereference';

  constructor(public httpClient:HttpClient) {
    super(httpClient)
  }

  // --------------------------------------------------------------------------------
  // Load les tables de référence
  // --------------------------------------------------------------------------------
  getReferenceTable(tablename: string):  Observable<HttpResponse<TableReferenceBO[]>> {
    return this.httpClient.get<TableReferenceBO[]>(`${this.resourceUrl}/getAllForTablename/`+tablename,
            {observe: 'response'});
  }

  getReferenceLabel(tablename: string, code: string): Observable<HttpResponse<TableReferenceBO>> {
    return this.httpClient.get<TableReferenceBO>(`${this.resourceUrl}/getReferenceLabel/`+tablename + '/' + code,
            {observe: 'response'});
  }


  saveTableReference(tableReference : TableReference) : Observable<HttpResponse<TableReference>>{
    return this.httpClient.post<TableReference>(`${this.resourceUrl}/saveReferenceTable/`, tableReference,
      {observe : 'response'});
  }

  deleteReferenceTable(tableReference: TableReference) : Observable<HttpResponse<TableReference>>{
    return this.httpClient.post<TableReference>(`${this.resourceUrl}/deleteReferenceTable`, tableReference,
      {observe : 'response'});
  }
}
