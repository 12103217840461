<!-- Notification : certificat à valider -->
<p-fieldset legend="{{getLabelForString('typeNotification', notificationBO.type)}}"
            *ngIf="notificationBO.type == 'CRTV'">
  <label>{{'UI802.notification.validerCertificat' | translate}}</label>
  <span class="p-buttonset">
    <button pButton type="button" label="{{'UI000.bouton.valider' | translate}}" icon="pi pi-check"
            (click)="valideCertificat('OK')"></button>
    <button pButton type="button" label="{{'UI000.bouton.invalider' | translate}}" icon="pi pi-check"
            (click)="valideCertificat('NOK')"></button>
  </span>
</p-fieldset>

<!-- Notification : imprimer et envoyer le certificat papier -->
<p-fieldset legend="{{getLabelForString('typeNotification', notificationBO.type)}}"
            *ngIf="notificationBO.type == 'IMPC'">
  <label>{{'UI802.notification.envoyerCertificatPapier' | translate}}</label>
  <span class="p-buttonset">
    <button pButton type="button" label="{{'UI000.bouton.confirmer' | translate}}" icon="pi pi-check"
            (click)="confirmerEnvoiCertificatPapier()"></button>
  </span>
</p-fieldset>

<!-- Notification : projet de certificat à ajouter -->
<p-fieldset legend="{{getLabelForString('typeNotification', notificationBO.type)}}"
            *ngIf="notificationBO.type == 'PADD'">
  <label>{{'UI802.notification.ajouterProjetCertificat' | translate}}</label>
  <span class="p-buttonset">
    <button pButton type="button" label="{{'UI000.bouton.confirmer' | translate}}" icon="pi pi-check"
            (click)="confirmerAjoutCertificat()"></button>
  </span>
</p-fieldset>

<!-- Notification : confirmer que le dossier dont le chassis est en black list peut être envoyé à la signature -->
<p-fieldset legend="{{getLabelForString('typeNotification', notificationBO.type)}}"
            *ngIf="notificationBO.type == 'CSBL'">
  <label>{{'UI901.notification.validerCertificatBlackListVin' | translate}}</label>
  <div class="p-fluid grid">
    <div class="field col">
      <label for="comment">{{'UI803.commentaire'| translate}}</label>
      <textarea pInputTextarea id="comment" [(ngModel)]="notificationBO.freeText" [rows]="5" [cols]="50" required></textarea>
    </div>
    <div class="field col">
      <div class="field-checkbox">
        <p-checkbox [(ngModel)]="notificationBO.prive" [binary]="true" inputId="commprive"></p-checkbox>
        <label for="commprive">{{'UI803.commentaire.prive' | translate}}</label>
      </div>
    </div>
  </div>
  <span class="p-buttonset">
    <button pButton type="button" label="{{'UI000.bouton.valider' | translate}}" icon="pi pi-check"
            (click)="valideCertificatBlackListVin('OK')"></button>
    <button pButton type="button" label="{{'UI000.bouton.invalider' | translate}}" icon="pi pi-check"
            (click)="valideCertificatBlackListVin('NOK')"></button>
  </span>
</p-fieldset>

<!-- Notification : envoyer manuellement les fichiers à ETAES -->
<p-fieldset legend="{{getLabelForString('typeNotification', notificationBO.type)}}"
            *ngIf="notificationBO.type == 'ETAM'">
  <label>{{'UI802.notification.confirmerEnvoiManuelEtaes' | translate}}</label>
  <span class="p-buttonset">
    <button pButton type="button" label="{{'UI000.bouton.confirmer' | translate}}" icon="pi pi-check"
            (click)="confirmerEnvoiManuelEtaes()"></button>
  </span>
</p-fieldset>

<!-- Notification : certificat disponible -->
<p-fieldset legend="{{getLabelForString('typeNotification', notificationBO.type)}}"
            *ngIf="notificationBO.type == 'CERT'">
  <label>{{'UI802.notification.certificatDisponibleClose' | translate}}</label>
  <span class="p-buttonset">
    <button pButton type="button" label="{{'UI000.bouton.confirmer' | translate}}" icon="pi pi-check"
            (click)="certificatDisponibleClose()"></button>
  </span>
</p-fieldset>

<!-- Notification : valider un projet de certificat -->
<p-fieldset legend="{{getLabelForString('typeNotification', notificationBO.type)}}"
            *ngIf="notificationBO.type == 'PCV'">
  <label>{{'UI802.notification.validerProjetCertificat' | translate}}</label>
  <span class="p-buttonset">
    <button pButton type="button" label="{{'UI000.bouton.valider' | translate}}" icon="pi pi-check"
            (click)="valideProjetCertificat('OK')"></button>
    <button pButton type="button" label="{{'UI000.bouton.invalider' | translate}}" icon="pi pi-check"
            (click)="valideProjetCertificat('NOK')"></button>
  </span>
</p-fieldset>

<!-- Notification : confirmer la bonne réception d'un mail -->
<p-fieldset legend="{{getLabelForString('typeNotification', notificationBO.type)}}"
            *ngIf="notificationBO.type == 'MEA'">
  <label>{{'UI802.notification.mailReceptionEtTraite' | translate}}</label>
  <span class="p-buttonset">
    <button pButton type="button" label="{{'UI000.bouton.confirmer' | translate}}" icon="pi pi-check"
            (click)="confirmerLectureMail()"></button>
  </span>
</p-fieldset>

<!-- liste des documents -->
<p-panel header="{{'UI803.documentlist' | translate}}">
  <p-table [value]="documents" [paginator]="true" [rows]="25" [showCurrentPageReport]="true" sortField="depotDate" [sortOrder]="-1"
           responsiveLayout="scroll"
           currentPageReportTemplate="{first} à {last} de {totalRecords} resultats" [rowsPerPageOptions]="[10,25,50]">
    >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="id">ID
          <p-sortIcon field="id"></p-sortIcon>
        </th>
        <th pSortableColumn="depotDate">{{'UI803.docDepotDate' | translate}}
          <p-sortIcon field="depotDate"></p-sortIcon>
        </th>
        <th pSortableColumn="typeDocument">{{'UI803.docTypeDocument' | translate}}
          <p-sortIcon field="typeDocument"></p-sortIcon>
        </th>
        <th pSortableColumn="userName">{{'UI803.docDeposePar' | translate}}
          <p-sortIcon field="userName"></p-sortIcon>
        </th>
        <th pSortableColumn="originalfilename">{{'UI803.originalFileName' | translate}}
          <p-sortIcon field="originalfilename"></p-sortIcon>
        </th>
        <th pSortableColumn="fileType">{{'UI803.docFileType' | translate}}
          <p-sortIcon field="fileType"></p-sortIcon>
        </th>
        <th pSortableColumn="size">{{'UI803.size' | translate}}
          <p-sortIcon field="size"></p-sortIcon>
        </th>
        <th *ngIf="(notificationBO.type == 'CRTV' || notificationBO.type == 'PADD') && demande != null && demande.typeDemandeBO.etaes != true">{{'UI803.docToMerge' | translate}}</th>
        <th *ngIf="notificationBO.type == 'SIGN'">{{'UI803.docASigner' | translate}}</th>
        <th>{{'UI803.docPrive' | translate}}</th>
        <th></th>
        <th *ngIf="(demande != null && (demande.statutCod == 10 || demande.statutCod == 15)) || user.agentDO215 == true"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-documents let-rowIndex="rowIndex">
      <tr [pSelectableRow]="documents">
        <td>{{documents.id}}</td>
        <td>{{documents.depotDate | date:'short'}}</td>
        <td>{{getLabelForString("typeDocument", documents.typeDocument)}} {{documents.numeroReception}}</td>
        <td>{{documents.userName}}</td>
        <td>{{documents.originalfilename}}</td>
        <td>{{documents.fileType}}</td>
        <td>{{documents.size}}</td>
        <td *ngIf="(notificationBO.type == 'CRTV' || notificationBO.type == 'PADD') && demande != null && demande.typeDemandeBO.etaes != true">
          <p-inputNumber [(ngModel)]="documents.docToMerge" [allowEmpty]="true"></p-inputNumber>
        </td>
        <td *ngIf="notificationBO.type == 'SIGN'"><p-checkbox [binary]="true" [(ngModel)]="documents.signer" [readonly]="true"></p-checkbox></td>
        <td><p-checkbox [binary]="true" [(ngModel)]="documents.prive" [readonly]="true"></p-checkbox></td>
        <td>
          <button *ngIf="documents.gedId != null" pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded p-button-success p-button-text"
                  (click)="displayDocument(documents)" ></button>
        </td>
        <td *ngIf="(demande != null && (demande.statutCod == 10 || demande.statutCod == 15)) || user.agentDO215 == true">
          <p-confirmPopup></p-confirmPopup>
          <button *ngIf="documents.todelete == false" pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-success p-button-text"
                  (click)="markDocumentToDelete($event, documents, rowIndex)">
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="pt-2">
    <button pButton type="button" label="{{'UI000.bouton.ajouter' | translate}}" icon="pi pi-file-o"
            (click)="ajouterDocument('GLOBAL')"
            *ngIf="user.agentDO215 == true || (demande != null && (demande.statutCod == 10 || demande.statutCod == 15 || demande.statutCod == 35))">
    </button>
  </div>

  <!-- Detail d'un nouveau document -->
  <p-card *ngIf="displayDetailDocument == true">
    <div class="p-fluid grid">
      <div class="field col">
        <label for="doctype">{{'UI803.docTypeDocument' | translate}}</label>
        <p-dropdown id="doctype" [options]="getReferenceTable('typeDocumentI')"
                    [(ngModel)]="selectedDocument.typeDocument"
                    optionValue="code"
                    optionLabel="libelle" [showClear]="true"
                    [required]="true"
                    [autoDisplayFirst]="false" #typeDocument="ngModel"
        ></p-dropdown>
        <small [style]="{color: '#F93154'}" *ngIf="typeDocument.errors?.required"> * {{'common.champ.obligatoire' | translate}}</small>
      </div>
      <div class="field col">
        <div class="field-checkbox">
          <p-checkbox [(ngModel)]="selectedDocument.prive" [binary]="true" inputId="binary1"></p-checkbox>
          <label for="binary1">{{'UI803.docPrive' | translate}}</label>
        </div>
      </div>
      <div class="field col">
        <p-fileUpload name="uploadFile" [files]="uploadedFiles" class="hideUploadContent"
                      [chooseLabel]="'UI803.fileupload' | translate" [class.hideButtonBar]="hideButtonBar"
                      [customUpload]="true" (uploadHandler)="uploadHandler($event)" [fileLimit]="1"
                      [maxFileSize]="fileUploadMaxSize" (cancel)="cancelUpload($event);"
                      [showUploadButton]="true" [showCancelButton]="true" >
          <ng-template pTemplate="content">
            <ul *ngIf="uploadedFiles.length">
              <li *ngFor="let file of uploadedFiles" [style]="{color: '#2ecc71'}">
                {{file.name}}
              </li>
            </ul>
            <small [style]="{color: '#5e82cb'}" *ngIf="uploadedFiles.length == 0">{{'common.upload.dragdrop' | translate}}</small>
          </ng-template>
        </p-fileUpload>
      </div>
    </div>
    <div class="pt-2">
      <span class="p-buttonset">
        <button *ngIf="uploadedFiles.length" pButton type="button" label="{{'UI000.bouton.sauver' | translate}}" icon="pi pi-check"
                (click)="sauverDocument()">
        </button>
        <button pButton type="button" label="{{'UI000.bouton.annuler' | translate}}" icon="pi pi-check"
                (click)="cancelDocument()">
        </button>
      </span>
    </div>
  </p-card>
</p-panel>
