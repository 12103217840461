import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AlertService} from '../../ui-components';
import {OAuthService} from 'angular-oauth2-oidc';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
  constructor(private router: Router,
              private alertService: AlertService,
              private oAuthService: OAuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => {
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.oAuthService.logOut();
            }
            if (err.status === 403) {
              this.router.navigateByUrl('/403').then();
            }
            if (err.status === 500) {
              this.alertService.error('Erreur', 'Erreur dans le system pour plus d\'information ' +
                'veuillez contacter l\'administrateur', {id: 'alert', autoClose: false});
            }
          }
        }
      )
    );
  }
}
