<h1 mat-dialog-title>
  {{'common.sessionExpiredHeader' | translate}}
</h1>
<div mat-dialog-content>
  <p class="message">{{('common.sessionExpired' | translate)}}</p>
  <p class="message">{{('common.reconnecterSession' | translate)}} </p>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onDismiss()" color="primary" type="button">{{'common.non' | translate}}</button>
  <button mat-button color="secondary" type="button" (click)="onConfirm()">{{'common.oui' | translate}}</button>
</div>
