<!--<div class="row no-gutters" [style.height]="height">-->
<div class="row no-gutters">
  <div *ngIf="labelSize!=0" class="{{'label  col-md-'+labelSize}}">
    <span>{{label}}</span>
  </div>

  <div class="{{labelSize==0 ? 'value col' :'value bold col-md-'+valueSize}}">
    <a (click)="onLinkClick()" [target]="newPage?'_blank':'_self'" *ngIf="isLink">{{value}}</a>
    <span *ngIf="!isLink" [class.break-line]="lineBreak">{{value}}</span>
    <ng-content></ng-content>
  </div>
</div>
