import {Injectable} from '@angular/core';
import {AbstractBusinessService, CommonService} from "../../../core";
import {Notification} from "../model/notification.model";
import {Demande} from "../../demande/model/demande.model";
import {PaiementBO} from "../../demande/model/paiement.model";
import {VersementBO} from "../../demande/model/versement.model";
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {TableReferenceBO} from "../../../core/models/table_reference.model";
import {ConstructeurBO} from "../../../core/models/constructeurs.model";
import {ServiceTechniqueBO} from "../../../core/models/servicetechnique.model";
import {DebuterAuditConstrRequest} from "../../constructeurs/model/debut.audit.constr.request";
import {DepotRapportAuditRequest} from "../../constructeurs/model/depot.rapport.audit.request";
import {NotifObj} from '../../administration/model/notifObj.model';
import {UserNotification} from "../../../core/models/user.notif.model";
import {UserNotificationRequest} from "../model/user.notification.request";
import {FactureIAPBO} from '../../demande/model/factureiap.model';
import {LazyFilterModel} from "../model/lazyfilter.model";
import {NotificationSimplifyPageBO} from "../model/notificationpage.model";

@Injectable({
  providedIn: 'root'
})

export class NotificationService extends AbstractBusinessService<Notification> {

  entityIdParamName: string;
  resourceUrl = CommonService.appConfig.backendUrl + 'notification';
  resourceUrlConstructeur = CommonService.appConfig.backendUrl + 'constructeur';
  resourceUrlSrvTech = CommonService.appConfig.backendUrl + 'servicetechnique';
  resourceUrlDemande = CommonService.appConfig.backendUrl + 'demande';
  resourceUrlFacture = CommonService.appConfig.backendUrl + 'facture';


  constructor(public httpClient: HttpClient) {
    super(httpClient)
  }

  getList(lazyFilterModel: LazyFilterModel): Observable<HttpResponse<NotificationSimplifyPageBO>> {
    return this.httpClient.post<NotificationSimplifyPageBO>(`${this.resourceUrl}/getByRole`, lazyFilterModel,
      { observe: 'response' });
  }

  getTypeNotification(): Observable<HttpResponse<TableReferenceBO[]>> {
    return this.httpClient.get<TableReferenceBO[]>(`${this.resourceUrl}/getTypeNotification`,
      { observe: 'response' });
  }

  // Une notification a été sélectionnée, on route vers l'écran correspondant au type de notification
  // 1. recherche la notification dans le backend pour vérifier si celle-ci n'a pas
  // été cloturée par un autre utilisateur
  // 2. vérifier que la notification que l'on souhaite exécuter est possible, que le dossier n'est
  // pas dans une situation incompatible avec la notification (statut incohérent avec l'action que
  // l'on veut faire)
  // 3. flag la notification comme étant en cours
  notificationSelected(notification: Notification): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/notificationSelected`, notification,
      { observe: 'response' });
  }

  loadConstructeur(id: number): Observable<HttpResponse<ConstructeurBO>> {
    return this.httpClient.get<ConstructeurBO>(`${this.resourceUrlConstructeur}/loadConstructeur/` + id,
      { observe: 'response' });
  }

  loadServiceTechnique(id: number): Observable<HttpResponse<ServiceTechniqueBO>> {
    return this.httpClient.get<ServiceTechniqueBO>(`${this.resourceUrlConstructeur}/loadServiceTechnique/` + id,
      { observe: 'response' });
  }

  loadDemande(id: number): Observable<HttpResponse<Demande>> {
    return this.httpClient.get<Demande>(`${this.resourceUrlDemande}/loadDemande/` + id,
      { observe: 'response' });
  }

  loadPaiement(id: number): Observable<HttpResponse<PaiementBO>> {
    return this.httpClient.get<PaiementBO>(`${this.resourceUrlFacture}/loadPaiement/` + id,
      { observe: 'response' });
  }

  loadVersement(id: number): Observable<HttpResponse<VersementBO>> {
    return this.httpClient.get<VersementBO>(`${this.resourceUrlFacture}/loadVersement/` + id,
      { observe: 'response' });
  }

  debuterAuditConstructeur(request: DebuterAuditConstrRequest): Observable<HttpResponse<void>> {
    return this.httpClient.post<void>(`${this.resourceUrl}/debuterAuditConstructeur`, request,
      { observe: 'response' });
  }

  confirmerLectureRapportAudit(request: number): Observable<HttpResponse<void>> {
    return this.httpClient.post<void>(`${this.resourceUrl}/confirmerLectureRapportAudit`, request,
      { observe: 'response' });
  }

  confirmerDepotRapportAudit(request: DepotRapportAuditRequest): Observable<HttpResponse<void>> {
    return this.httpClient.post<void>(`${this.resourceUrl}/confirmerDepotRapportAudit`, request,
      { observe: 'response' });
  }

  profilModifieTraite(request: number): Observable<HttpResponse<void>> {
    return this.httpClient.post<void>(`${this.resourceUrl}/profilModifieTraite`, request,
      { observe: 'response' });
  }

  newNotification(code: string, constructeurTiersId: number, infoComplemeantire: string): Observable<HttpResponse<void>> {
    let params = new HttpParams();
    params = params.set('code', code);
    params = params.set('infoComplemeantire', infoComplemeantire);
    return this.httpClient.post<void>(`${this.resourceUrl}/newNotification`, constructeurTiersId,
      { observe: 'response', params });
  }

  getNotificationsPA(): Observable<HttpResponse<Notification[]>> {
    return this.httpClient.get<Notification[]>(`${this.resourceUrl}/getNotificationsPA`,
      { observe: 'response' });
  }

  deleteNotification(demande: Demande): Observable<HttpResponse<Notification>> {
    return this.httpClient.post<Notification>(`${this.resourceUrl}/deleteNotificationByDemandeId`, demande,
      { observe: 'response' });
  }

  getNotificationsPAByDateCreation(criteriaDateCreationBegin: Date, criteriaDateCreationEnd: Date): Observable<HttpResponse<Notification[]>> {
    return this.httpClient.get<Notification[]>(`${this.resourceUrl}/getNotificationsPAByDateCreation` + '/' + criteriaDateCreationBegin + '/' + criteriaDateCreationEnd,
      { observe: 'response' });
  }

  getNotificationsPAByDateTraitement(criteriaDateTraitementBegin: Date, criteriaDateTraitementEnd: Date): Observable<HttpResponse<Notification[]>> {
    return this.httpClient.get<Notification[]>(`${this.resourceUrl}/getNotificationsPAByDateTraitement` + '/' + criteriaDateTraitementBegin + '/' + criteriaDateTraitementEnd,
      { observe: 'response' });
  }

  reactivateNotification(notification: Notification): Observable<HttpResponse<void>> {
    return this.httpClient.post<void>(`${this.resourceUrl}/reactivateNotification`, notification,
      { observe: 'response' });
  }

  getnotwtas(paiementid: number): Observable<HttpResponse<boolean>> {
    return this.httpClient.get<boolean>(`${this.resourceUrl}/getnotwtas` + '/' + paiementid,
      { observe: 'response' });
  }

  updateFactureStatutPaiement(paiementid: number): Observable<HttpResponse<void>> {
    return this.httpClient.post<void>(`${this.resourceUrl}/updateFactureStatutPaiement`, paiementid,
      { observe: 'response' });
  }

  newNotif(code: string, constructeurTiersId: number, notifObj: NotifObj): Observable<HttpResponse<void>> {
    let params = new HttpParams();
    params = params.set('code', code);
    // params = params.set('constructeurTiersId', constructeurTiersId.toString());
    return this.httpClient.post<void>(`${this.resourceUrl}/newNotif`, notifObj,
      { observe: 'response', params });
  }

  getUserNotifications(): Observable<HttpResponse<UserNotification>> {
    return this.httpClient.get<UserNotification>(`${this.resourceUrl}/getUserNotifications`,
      { observe: 'response' });
  }

  updateUserNotifications(userNotif: UserNotificationRequest): Observable<HttpResponse<void>> {
    return this.httpClient.post<void>(`${this.resourceUrl}/updateUserNotifications`, userNotif,
      { observe: 'response' });
  }

  getFacturesByPaiement(paiementid: number): Observable<HttpResponse<FactureIAPBO[]>> {
    return this.httpClient.get<FactureIAPBO[]>(`${this.resourceUrl}/getFacturesByPaiement` + '/' + paiementid,
      { observe: 'response' });
  }


}
