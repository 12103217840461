import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {CommonService} from '../../core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  http: HttpClient;
  homeDecorations: Array<{ url: string, quote: string }> =
    [
      {url: '../../../assets/images/home.png', quote: ''}
    ];

  constructor(private route: ActivatedRoute, private router: Router, private httpBackend: HttpBackend, private oAuthService: OAuthService) {
    this.http = new HttpClient(httpBackend);
  }

  /*
  Some redirects are forced to '/choix-enterprise' specifically for Omega as there's a required choice to be made after login.
  Otherwise, redirection should follow what the user asked before authentification
   */

  ngOnInit(): void {
    if (this.oAuthService.hasValidAccessToken()) {
      if (sessionStorage.getItem('redirectAfterLogin') && sessionStorage.getItem('redirectAfterLogin') !== '/' && sessionStorage.getItem('redirectAfterLogin') !== '/home') {
        this.router.navigateByUrl(sessionStorage.getItem('redirectAfterLogin'));
      } else {
        this.router.navigateByUrl('/notification/list');
        // this.router.navigateByUrl('/home');
      }
    }
    sessionStorage.setItem('redirectAfterLogin', '/notification/list');
    // sessionStorage.setItem('redirectAfterLogin', '/home');
    // This should be used if there wouldn't be a required choice to be made after login (Enterprise selection)
    // sessionStorage.setItem('redirectAfterLogin', this.route.snapshot.queryParams['returnUrl'] || '/');
  }

  onConnect(): void {
    if (CommonService.appConfig.disableSecurity) {
      this.router.navigateByUrl('/notification/list');
      // this.router.navigateByUrl('/home');
    } else {
      this.oAuthService.initLoginFlow();
      // We could provide a login in a popup but then the redirect uri shoulld be the silent-refresh.html (and the page needs to be reloaded to take the login into account due to the actual design)
      // this.oAuthService.initLoginFlowInPopup().then(() => window.location.reload());
    }
  }

}
