import {Injectable} from '@angular/core';
import {CommonService} from '../../core';

@Injectable({
  providedIn: 'root'
})

export class CommonTestingService {

  static initCommonService(): void {
    CommonService.appConfig = {
      env: 'Development',
      version: '3.0.0-SNAPSHOT',
      backendUrl: 'https://kubernetes.localhost/wtas/api/',
      checkAllUrl: 'https://kubernetes.localhost/wtas/check/',
      sessionExtensionMin: 300,
      disableSecurity: true,
      acrValues: 'fas',
      messagesPageSize: 10,
      mockBackend: true
    };

    CommonService.userInfo = {
      id: '1',
      username: 'UserName',
      firstName: 'FirstName',
      lastName: 'LastName',
      email: 'wtas@spw.wallonie.be',
      locale: 'fr',
      enabled: true
    };
  }

}
