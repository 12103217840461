export class SearchDemandeRequest {
    // TODO ajouter les critères de l'écran recherche de demandes
    mesDossiers?: boolean;
    demandeTypeId?: string;
    statutDemande?: number;

    demandeTypeCodes?: string[];
    nomDemandeur?: string;
    numeroDossier?: string;
    numeroReception?: string;
    referenceClient?: string;
    numeroChassis?: string;
    csvIAPFacture?: string;
    dateSoumissionBegin?: Date;
    dateSoumissionEnd?: Date;

    reportNumber?: string;
    email?: string;
    ifast?: boolean;

}
