import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {LabelComponent, LoaderComponent, SessionExpiredDialogComponent,} from '../ui-components';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

const angularMaterial = [
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
  MatToolbarModule,
  MatMenuModule
];


@NgModule({
  declarations: [LabelComponent,
    LoaderComponent,
    SessionExpiredDialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    ...angularMaterial
  ],
  exports: [...angularMaterial,
    LabelComponent,
    LoaderComponent,
    SessionExpiredDialogComponent
  ],
  entryComponents: [
  ]
})
export class UiComponentsModule {
}
