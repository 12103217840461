import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'stripHtml'})
export class StripHtmlPipe implements PipeTransform {

  constructor() {
  }

  transform(value: string): string {
    if (value) {
      const tmp = document.createElement('DIV');
      tmp.innerHTML = value;
      return tmp.textContent || tmp.innerText || '';
    } else {
      return '';
    }
  }

}
