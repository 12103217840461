<div class="error-container">
  <div class="row text">

    <div class="col-md-8 col-md-offset-3">

      <h1 style="width: 189px">Error Page!</h1>

      <div [hidden]="!errorMessage">
        <div class="alert alert-danger">{{errorMessage}}
        </div>
      </div>
      <div [hidden]="!error403" class="alert alert-danger">You are not authorized to access this page.
      </div>
      <div [hidden]="!error404" class="alert alert-danger">The page asked was not found.
      </div>
      <h1 class="error-number">
        {{error403 ? '403' : '404'}}
      </h1>
    </div>
  </div>
</div>
