import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {CommonService} from '../../../core';
import {ApiModel} from '../../model/api.model';
import {switchMap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CheckAllService {

  private httpWithoutInterceptor: HttpClient;

  constructor(private http: HttpClient, private httpBackend: HttpBackend) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }

  // getRestApiHealth(model: ApiModel): Observable<HttpResponse<any>> {
  //   return this.httpWithoutInterceptor.get(`${CommonService.appConfig.backendUrl}${model.url}`,
  //     {observe: 'response'});
  // }


  //http://localhost:49557/check-all/rest-api-all-health
  getRestApiHealth(): Observable<HttpResponse<any>> {
    return this.httpWithoutInterceptor.get(`${CommonService.appConfig.checkAllUrl}rest-api-all-health`,
      {observe: 'response'});
  }

  getGatewayHealth(): Observable<HttpResponse<any>> {
    return this.http.get(`${CommonService.appConfig.backendUrl}/actuator/health`,
      {observe: 'response'});
  }

  // getAppVersion(): Observable<HttpResponse<any>> {
  //   return this.http.get(`${this.backendUrl}/app-version`,
  //     {observe: 'response'});
  // }

  getEnvHealth(): Observable<ApiModel[]> {
    return this.httpWithoutInterceptor.get<ApiModel[]>(`${CommonService.appConfig.checkAllUrl}env-health`,
      {observe: 'response'})
      .pipe(switchMap(resp => of(resp.body)));
  }

  getServices(): Promise<any> {
    return this.httpWithoutInterceptor.get('./assets/apis.json').toPromise();
  }

  getContacts(): Promise<any> {
   return this.httpWithoutInterceptor.get('./assets/contact.json').toPromise();
  }
}

