export class Commentaire {

    id?: number;	// primary key
    dateCommentaire?: Date;
    commentaire?: string;
    type?: string;
    auteur?: string;
    demandeId?: number;			// FK WtasDemande
    constructeurId?: number;	// FK WtasConstructeurTiers
    servicetechniqueId?: number;	// FK WtasServiceTechnique
    siteProductionId?: number;	// FK WtasSiteProduction
    auditId?: number;			// FK WtasAudit
    surCertificat?: boolean;
    prive?: boolean;
    commentaireStandardCode?: string;
    commentaireStandardLabel?: string;

    numeroDossier?: string;

}
