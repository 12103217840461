import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {Observable} from 'rxjs';
import {LazyLoadEvent} from 'primeng/api';
import {ApiCollection, AppDocument, BusinessEntity, Ecran, Type, UserAction, ValueLabel} from '..';
import {ApiCache} from "./api-cache.service";
import {TableReferenceBO} from "../models/table_reference.model";
import {CommonService} from "./common.service";

export abstract class AbstractBusinessService<T> {

  abstract resourceUrl: string;
  abstract entityIdParamName: string;
  public requesterName: string;
  resourceUrlTableReference = CommonService.appConfig.backendUrl + 'tablereference';

  private _http: HttpClient;

  public apiCache: ApiCache;

  get http(): HttpClient {
    return this._http;
  }

  set http(ht: HttpClient) {
    this._http = ht;
  }

  protected constructor(http: HttpClient) {
    this.http = http;
    this.apiCache = new ApiCache(this.http);
  }

  find(id: string, enterpriseId: number, httpHeaders?: HttpHeaders): Observable<HttpResponse<T>> {
    let params = new HttpParams();
    params = params.set('enterpriseId', enterpriseId.toString());
    return this.http.get<T>(`${this.resourceUrl}/${id}`, {
      headers: httpHeaders, observe: 'response',
      params: params
    });
  }

  create(enterpriseId: number, lotId: number, entityType: string, entityId: number, entity: BusinessEntity, documents: File[], screenName?: Ecran, action?: UserAction): Observable<HttpResponse<T>> {
    if (action == null) {
      action = UserAction.CREATE;
    }

    let params = new HttpParams();
    params = params.set('enterpriseId', enterpriseId.toString());

    let formData = new FormData();
    formData = entity.getFormData(formData);
    // Append files to the virtual form.
    for (const file of documents) {
      formData.append('documents', file);
    }

    // Send it.
    return this.http.post<T>(`${this.resourceUrl}`, formData, {observe: 'response', params: params});
  }


  edit(enterpriseId: number, lotId: number, entityType: string, entityId: number, entity: BusinessEntity, documents: File[], screenName?: Ecran, action?: UserAction): Observable<HttpResponse<T>> {
    if (action == null) {
      action = UserAction.EDIT;
    }

    let params = new HttpParams();
    params = params.set('enterpriseId', enterpriseId.toString());
    params = params.set(this.entityIdParamName, entityId.toString());

    let formData = new FormData();
    formData = entity.getFormData(formData);
    // Append files to the virtual form.
    for (const file of documents) {
      formData.append('documents', file);
    }

    // Send it.
    return this.http.put<T>(`${this.resourceUrl}` + '/' + entityId, formData, {observe: 'response', params: params});
  }

  load(event: LazyLoadEvent, enterpriseId: number, lotId: number, screenName ?: Ecran, action ?: UserAction): Observable<HttpResponse<ApiCollection<T>>> {
    if (action == null
    ) {
      action = UserAction.SEARCH;
    }
    let params = new HttpParams();
    if (event.filters) {
      Object.keys(event.filters).forEach(filterKey => {
        const filterMetaData = event.filters[filterKey];
        if (filterMetaData.value) {
          switch (filterKey) {
            case 'state':
            case 'initiator':
              filterMetaData.value.forEach((value: ValueLabel) => {
                params = params.append(filterKey, value.value);
              })
              break;
            case 'type':
              filterMetaData.value.forEach((value: Type) => {
                params = params.append(filterKey, value.id);
              })
              break;
           /** case 'startDate':
            case 'endDate':
            case 'awardDate':
            case 'createDate':
            case 'sendDate':
            case 'dateTransmission':
            case 'orderDate':
              let dateInterval: string[];
              filterMetaData.value.forEach((value: Date) => {
                params = params.append(filterKey, this._datePipe.transform(value, 'yyyy-MM-dd'));
              })
              break;
            **/
           case 'amountUntaxed': // TODO float interval for /purchaseOrders
              break;
            default:
              params = params.set(filterKey, filterMetaData.value);
          }
        }
      })
    }
    if (event.sortField) {
      params = params.set('sort', (event.sortOrder < 0 ? '-' : '') + event.sortField);
    }
    if (lotId) {
      params = params.set('lotId', lotId.toString());
    }
    params = params.set('enterpriseId', enterpriseId.toString());
    params = params.set('pageSize', event.rows.toString());
    params = params.set('page', ((event.first / event.rows) + 1).toString());
    return this.http.get<ApiCollection<T>>(`${this.resourceUrl}`
      , {
        headers: this.getHeaders(screenName, action),
        observe: 'response',
        params: params
      });
  }

  getDocument(enterpriseId: number, entityId: number, documentId: number | string, entityType?: string, messageId?: number): Observable<HttpResponse<Blob>> {
    let params = new HttpParams();
    params = params.set('enterpriseId', enterpriseId.toString());

    let url: string;
    if (entityType && messageId) {
      url = `${this.resourceUrl + entityType}/${entityId}/messages/${messageId}/attachments/${documentId}`;
    } else {
      url = `${this.resourceUrl}/${entityId}/documents/${documentId}`;
    }


    return this.http.get(url
      , {
        // headers: headers,
        observe: 'response',
        params: params,
        responseType: 'blob'
      });
  }

  loadDocuments(enterpriseId: number, entityId: number): Observable<HttpResponse<AppDocument[]>> {
    let params = new HttpParams();
    params = params.set('enterpriseId', enterpriseId.toString());
    return this.http.get<AppDocument[]>(`${this.resourceUrl}/${entityId}/documents`, {
      observe: 'response',
      params
    });
  }

  saveDocuments(enterpriseId: number, entityId: number, documents: File[]): Observable<HttpResponse<AppDocument[]>> {
    let params = new HttpParams();
    params = params.set('enterpriseId', enterpriseId.toString());

    const formData = new FormData();
    // Append files to the virtual form.
    for (const file of documents) {
      formData.append('documents', file);
    }

    return this.http.post<AppDocument[]>(`${this.resourceUrl}/${entityId}/documents`, formData, {
      observe: 'response',
      params
    });
  }


  deleteDocument(enterpriseId: number, entityId: number, docId: string): Observable<HttpResponse<string>> {
    let params = new HttpParams();
    params = params.set('enterpriseId', enterpriseId.toString());

    return this.http.delete<string>(`${this.resourceUrl}/${entityId}/documents/${docId}`, {
      observe: 'response',
      params
    });
  }

  getHeaders(screenName: Ecran, action: UserAction, freeInfo ?: string): HttpHeaders {
    if (screenName != null) {
      let headers = new HttpHeaders(
        {
          'screen-name': screenName,
          'user-action': action
        });

      if (freeInfo != null) {
        headers = new HttpHeaders(
          {
            'screen-name': screenName,
            'user-action': action,
            'free-info': freeInfo,
          });

      }
      console.log(headers.get('screen-name'));
      return headers;
    } else {
      return null;
    }
  }



}
