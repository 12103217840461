export class Alert {
  id: string;
  severity: Severity;
  title: string;
  message: string;
  autoClose: boolean;
  time = 20000;
  keepAfterRouteChange: boolean;
  fade: boolean;

  constructor(init?: Partial<Alert>) {
    Object.assign(this, init);
  }
}

export enum Severity {
  INFO,
  SUCCESS,
  WARNING,
  ERROR,

}
